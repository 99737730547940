<template>
	<div class="ae-view-start">
		<div class="ae-inner">
			<div class="ae-headline ae-fs-big" v-text="headline" />
			<div class="ae-text" v-html="text" />
			<div class="ae-buttoncontainer">
				<div class="left">
					<router-link class="ae-settingslink" :to="{ name: 'settings' }" v-text="linktext"></router-link>
					<br>
					<a class="privacylink" @click="redirectToPrivacy();" v-text="privacytext"></a>
				</div>
				<div class="right">
					<a class="ae-button deny" @click="denySettings();" v-text="buttontextdeny"></a>
					<br>
					<a class="ae-button" @click="applySettings();" :style="{'background-color':$root.color}" v-text="buttontext"></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			'headline': '',
			'text': '',
			'linktext': '',
			'buttontext': '',
			'privacytext': '',
			'privacylink': '',
			'buttontextdeny': '',
		}
	},
	mounted () {
		if (this.$parent.lang == 'DE') {
			this.headline = 'Zuerst die Cookies, dann geht\'s weiter.';
			this.text = 'Diese Website nutzt mehrere Arten von Cookies: Essentielle Cookies benötigen wir, damit die Website reibungslos für Sie funktioniert. Marketing-Cookies helfen uns, das Verhalten der Websitebesucher analysieren und Kampagnen optimieren zu können. Funktionelle Cookies stellen sicher, dass integrierte Tools von Drittanbietern optimal dargestellt werden.<br><br>Sie können jederzeit festlegen, welche Cookies sie zulassen möchten und welche nicht (Zugriff zu den Cookie-Einstellungen finden Sie im Websitebereich "Cookies").';
			this.linktext = 'Cookie-Einstellungen';
			this.buttontext = 'Alle Cookies zulassen';
			this.buttontextdeny = 'Ablehnen';
			this.privacytext = 'Privacy & Cookies';
			this.privacylink = '/de/privacy';
		} else if (this.$parent.lang == 'IT') {
			this.headline = 'Prima i cookie, poi si continua.';
			this.text = 'Questo sito web utilizza diversi tipi di cookie: i cookie essenziali, che necessitiamo per far funzionare il sito web senza problemi. I cookie marketing, che ci aiutano ad analizzare il comportamento dei visitatori del sito e ad ottimizzare le campagne. I cookie funzionali, che assicurano la visualizzazione ottimale degli strumenti integrati da terzi.<br><br>Potete decidere in qualsiasi momento quali cookie desidera accettare e quali no (l\'accesso alle impostazioni dei cookie si trova nella sezione del sito "Cookie").';
			this.linktext = 'Impostazioni cookie';
			this.buttontext = 'Accetta tutti i cookie';
			this.privacylink = '/it/privacy';
			this.privacytext = 'Privacy e Cookie policy';
			this.buttontextdeny = 'Rifiuta tutti';
		} else {
			this.headline = 'First of all, let\'s talk about cookies.';
			this.text = 'This website uses different kinds of cookies: essential cookies to keep our website running smoothly. Marketing cookies help us analyze user behavior and optimize our ad campaigns. Functional cookies ensure that integrated third-party tools are displayed optimally.<br><br>You can specify at any time which cookies you wish to allow and which you do not (access to the cookie settings can be found in the website section "Cookies").';
			this.linktext = 'Cookie settings';
			this.buttontext = 'Allow all cookies';
			this.privacylink = '/en/privacy';
			this.privacytext = 'Privacy & Cookies';
			this.buttontextdeny = 'Deny';
		}
		
	},
	methods: {
		redirectToPrivacy: function () {
			var bReload = false;
			if (document.cookie.indexOf('ae-cookiebanner') >= 0) {
				bReload = true;
			}
			this.$parent.closeCookieBanner()
			this.$parent.setClosedCookie()
			this.$parent.setCatCookies(true, false, false)
			this.$parent.checkScripts()
			this.$parent.checkIfReloadIsRequired(bReload)
			window.open(
				this.privacylink,
				'_blank' 
			)
		},
		applySettings: function () {
			var bReload = false;
			if (document.cookie.indexOf('ae-cookiebanner') >= 0) {
				bReload = true;
			}
			this.$parent.closeCookieBanner()
			this.$parent.setClosedCookie()
			this.$parent.setCatCookies(true, true, true)
			this.$parent.checkScripts()
			this.$parent.checkIfReloadIsRequired(bReload)
		},
		denySettings: function () {
			var bReload = false;
			if (document.cookie.indexOf('ae-cookiebanner') >= 0) {
				bReload = true;
			}
			this.$parent.closeCookieBanner()
			this.$parent.setClosedCookie()
			this.$parent.setCatCookies(true, false, false)
			this.$parent.checkScripts()
			this.$parent.checkIfReloadIsRequired(bReload)
		}
	}
}
</script>
